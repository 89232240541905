/**
 * Javascript to show and hide cookie banner using localstorage
 * Adapted from https://github.com/kolappannathan/bootstrap-cookie-banner/blob/main/src/cookie-banner.js
 */

const currentVersion = "0";
const keyAccept = "cb_isCookieAccepted";
const keyVersion = "cb_cookiePolicyVersion";
const idBanner = "cb-cookie-banner";

/**
 * @description Shows the cookie banner
 */
 function showCookieBanner() {
    let cookieBanner = document.getElementById(idBanner);
    cookieBanner.style.display = "block";
}

/**
 * @description Hides the Cookie banner and saves the value to localstorage
 */
function hideCookieBanner() {
    localStorage.setItem(keyAccept, "yes");
    localStorage.setItem(keyVersion, currentVersion);

    let cookieBanner = document.getElementById(idBanner);
    cookieBanner.style.display = "none";
}

/**
 * @description Checks the localstorage and shows Cookie banner based on it.
 */
function initializeCookieBanner() {
    let isCookieAccepted = localStorage.getItem(keyAccept);
    let policyVersion = localStorage.getItem(keyVersion);
    if(isCookieAccepted === null ||
        policyVersion === null ||
        policyVersion !== currentVersion) {
        localStorage.setItem(keyAccept, "no");
        showCookieBanner();
    }
    if(isCookieAccepted === "no") {
        showCookieBanner();
    }
}

const cookieInit = () => {
    initializeCookieBanner();
    // Hook up callback to window object
    window.cookie_hideBanner = hideCookieBanner;
}

export default cookieInit;